import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {
    $('.homeHeaderCarousel').slick({
        infinite: false,
        arrows: false,
        dots: false,
        fade: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    autoplay: true
                }
            }
        ]
    });
    $('.homeHeaderCarousel').on('afterChange', function() {
        var dataId =  parseInt($('.slick-current').attr('data-slick-index')) + 1;
        $('.homeHeaderCarouselLogo').each(function() {
            $(this).removeClass('active');
            if ($(this).data('slide') === dataId) {
                $(this).addClass('active');
            }
        });
    });
    $('.homeHeaderCarouselLogo').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.homeHeaderCarousel').slick('slickGoTo', slideno - 1);
    });
});
